@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  font-family: "Roboto", sans-serif;
  padding: 0;
  margin: 0;
  @apply text-sm;
  font-weight: 400;
  overflow: overlay;
}
body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-track {
  border-radius: 6px;
}

body::-webkit-scrollbar-thumb {
  @apply bg-sky-400;
  border-radius: 6px;
}

.mainBody {
  flex: 1;
  padding-top: 90px;
  width: 100vw;
  overflow-x: hidden;
  max-width: 100%;
}
/*
nav {
  @apply bg-stone-800 text-white flex items-center h-14 px-12;
  justify-content: space-between;
}

footer {
  @apply bg-stone-800 px-20 py-8 text-white;
  display: flex;
  flex-direction: column;
}

.footTop {
  display: flex;
  flex: row;
  align-items: center;
  margin-bottom: 16px;
}

.footTop .left,
.footTop .right {
  flex: 1;
}

.footTop .right {
  text-align: right;
}

.footBot {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
*/
.logo {
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navLinks {
  display: flex;
  gap: 24px;
}

.separator {
  border-bottom: 2px solid;
  @apply my-10 m-1 border-gray-400;
}

.small {
  width: 150px;
}

/*----_Programs Page -----*/
.programs,
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  @apply py-10;
}

.programs .college {
  width: 100vw;
  display: flex;
  justify-content: center;
}

.programs a {
  text-decoration: underline;
  text-underline-offset: 4px;
}

.programs .college.c1 {
  @apply bg-stone-900 text-stone-300;
}

.c1 a {
  @apply text-stone-400 font-semibold;
}

.programs .college.c2 {
  @apply bg-stone-600 text-stone-50;
}

.c2 a {
  @apply text-stone-100 font-semibold;
}

.programs .college.c3 {
  @apply text-stone-900;
}

.college .content {
  display: flex;
  justify-content: center;
  gap: 44px;
}

.college .content .collegeLogo {
  max-width: 280px;
  min-width: 100px;
}

.college .content .departments {
  flex: 1;
}

.collegeLogo img {
  width: 100%;
}

/**----About----**/

.about .description {
  width: 1200px;
  @apply pt-5;
}

.about .cover {
  width: max(570px);
  height: max(285px);
  background-image: url("./res/peaci-ched-anniv.jpg");
  background-size: cover;
  background-position: center;
}

/**----Admin----**/

.webMain {
  height: 100vh;
  width: 100vw;
}

.loginPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  gap: 16px;
  padding-bottom: 50px;
}

.loginLogoDiv {
  width: 200px;
}

.markdown {
  @apply py-6;
}

.markdown p {
  @apply mb-2 text-stone-600 text-lg;
}

.markdown h3 {
  @apply text-xl text-sky-600 font-bold;
}
